import {useCallback, useState} from 'react'
import PrintIcon from '@mui/icons-material/Print'
import {Backdrop, Box, CircularProgress, Theme} from '@mui/material'
import axios, {AxiosResponse} from 'axios'

import {getDocumentFile, printFile} from 'src/utils/endpoints'
import {useAppSelector} from 'src/hooks'
import { useReactToPrint } from 'react-to-print'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'

export const PrintDoc = ({edition, nameDoc, isPdf, fileName}: {edition: number, fileName?: string, isPdf?: boolean; nameDoc: string}) => {
  const lang = useAppSelector((state) => state.changeLang.lng)
  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(false)

  // const onBeforeGetContent = useCallback(async () => {
  //   const text = refText?.current
  //   const doc = printDoc?.current
  //   if (text && doc) {
  //     const tagName = doc.getElementsByTagName('div')[0]
  //     const newTag = document.createElement('div')
  //     for (let i = 1; i < tagName.children.length; i++) {
  //       const clone = tagName.children[i].cloneNode(true)
  //       newTag.append(clone)
  //       if (i == 3) break
  //     }
  //     if (newTag.children.length) {
  //       text.insertBefore(newTag, text.firstElementChild)
  //     }
  //   }
  // }, [
  // ])

  const currentRef = useCallback(() => {
    return <pre>fsfa</pre>
  }, [
  ])
  const handlePrintText = useReactToPrint({
    content: currentRef as any,
    pageStyle: `@page { margin: 50rem !important; padding: 50px !important; }`,
    documentTitle: nameDoc,
    // onBeforeGetContent,
    // onAfterPrint: () => {
    //   setTextHighlanderToPrint(undefined)
    // },
    removeAfterPrint: true,
  })

  // const onClick = () => {
  //   const selection = printText?.getSelection()
  //   handlePrintText()
    
  //   console.log(selection);
  //   if(selection) {
  //     // HighlightedText(selection)
  //   console.log(selection.toString());
      
  //   } else {
  //     (async () => {
  //       setOpen(true)
  //       axios
  //         .get(`${printFile}${edition}&lang=${lang === 'ru' ? 'ru' : 'kg'}`, {
  //           responseType: 'blob',
  //         })
  //         .then((response: AxiosResponse) => {
  //           const file = new Blob([response.data], {type: 'application/pdf'})
  //           const fileURL = URL.createObjectURL(file)
  //           window.open(fileURL)
  //           setOpen(false)
  //         })
  //         .catch(() => {
  //           setOpen(false)
  //         })
  //     })()
  //   }
  // }

  const printF = async () => {
    setOpen(true)
    axios
      .get(
        isPdf
          ? `${getDocumentFile}${fileName}`
          : `${printFile}${edition}&lang=${lang === 'ru' ? 'ru' : 'kg'}`,
        {
          responseType: 'blob',
        },
      )
      .then((response: AxiosResponse) => {
        const file = new Blob([response.data], {type: 'application/pdf'})
        const fileURL = URL.createObjectURL(file)
        window.open(fileURL)
        setOpen(false)
      })
      .catch(() => {
        setOpen(false)
      })
  }

  return (
    <div>
      {isPdf ? (
        <PictureAsPdfIcon fontSize={'large'} sx={print} onClick={() => printF()} />
      ) : (
        <PrintIcon fontSize={'large'} sx={print} onClick={() => printF()} />
      )}

      <Backdrop
        sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
    </div>
  )
}

const print = (theme: Theme) => ({
  cursor: 'pointer',
  color: 'primary.main',
  mt: '6px',
  [theme.breakpoints.between('xs', 'sm')]: {
    display: 'none',
  },
})


interface Props {
  textHighlanderToPrint: React.ReactNode
  refText: {current: HTMLDivElement | null}
}

const HighlightedText = ({textHighlanderToPrint, refText}: Props) => {
  return (
    <Box ref={refText} sx={{display: 'none'}}>
      {textHighlanderToPrint && (
        <>
          <Box>
            <pre>{textHighlanderToPrint}</pre>
          </Box>
        </>
      )}
    </Box>
  )
}