import React from 'react'
import {Controller, FormProvider, SubmitHandler, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import ClearIcon from '@mui/icons-material/Clear'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import SearchIcon from '@mui/icons-material/Search'
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Theme,
  Typography,
} from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Checkbox from '@mui/material/Checkbox/Checkbox'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'

import {useAppDispatch, useAppSelector} from '../../hooks'
import {setFilterDatas} from '../../redux'
import {
  AdvancedResearch,
  Dates,
  DatesGroup,
  Fields,
  ImportantDocs,
  Selects,
} from '../index'

const RootForm = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const methods = useForm<IForm>()
  const {register, handleSubmit, getValues, control, resetField} = methods
  const lang = useAppSelector((state) => state.changeLang.lng)
  const {t} = useTranslation()

  const onSearch: SubmitHandler<IForm> = (formData) => {
    navigate(`/list-docs/${lang}`)
    localStorage.setItem(
      'search-by-text',
      lang == 'ru' ? getValues('searchByTextRu') : getValues('searchByTextKg'),
    )
    dispatch(setFilterDatas(formData))
  }

  const radioFields = [
    {
      name: '',
      label: 'all-categories',
    },
    {
      name: 'npakr',
      label: 'normativeLegalActs.title',
    },
    {
      name: 'mds',
      label: 'internationalLaw.title',
    },
    {
      name: 'sud',
      label: 'judicialActs.title',
    },
  ]
  const checkboxFields: Array<{
    name: 'isPercentage' | 'includeInvalid'
    label: string
    value: boolean
  }> = [
    {
      name: 'isPercentage',
      label: t('odd-search'),
      value: true,
    },
    {
      name: 'includeInvalid',
      label: t('exclude-document-from-search'),
      value: false,
    },
  ]

  return (
    <Grid sx={wrapper}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSearch)}>
          <Box sx={form}>
            <Typography variant={'h1'} fontSize='22px'>
              {t('categories')}
            </Typography>
            <TextField
              id='outlined-size-small'
              size='small'
              sx={{borderRadius: '9px', margin: '10px 0'}}
              fullWidth
              placeholder={t('categories')}
              {...register(lang === 'ru' ? 'keyWordRus' : 'keyWordKyr')}
            />
            <Box
              sx={{
                display: 'flex',
                gap: '20px',
                alignItems: 'end',
                m: '10px 0',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: '10px',
                  justifyContent: 'space-between',
                }}
              >
                <Dates
                  control={control}
                  label={t('form.dates.title')}
                  registerText={'dateAdopted'}
                />
                <Box sx={{cursor: 'pointer'}}>
                  <ClearIcon
                    onClick={() => resetField('dateAdopted')}
                    sx={{
                      color: '#666666',
                      fontSize: '30px',
                      background: '#f8f8f8',
                      mb: '-40px',
                      p: '5px',
                      borderRadius: '7px',
                    }}
                  />
                </Box>
              </Box>
              <Fields text={t('form.number')} registerText='number' />
            </Box>
            {checkboxFields.map((i) => (
              <Controller
                control={control}
                key={i.name}
                name={i.name}
                defaultValue={i.value}
                render={({field: {onBlur, onChange, ref}}) => (
                  <FormControlLabel
                    sx={{mb: '20px'}}
                    control={
                      <Checkbox
                        onBlur={onBlur}
                        onChange={onChange}
                        inputRef={ref}
                        defaultChecked={i.value}
                      />
                    }
                    label={i.label}
                  />
                )}
              />
            ))}
            <Controller
              control={control}
              name='refClassId'
              defaultValue=''
              render={({field: {onBlur, onChange, ref}}) => (
                <FormControl sx={{display: 'grid'}}>
                  <FormLabel id='demo-row-radio-buttons-group-label'>
                    {t('sections')}
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby='demo-row-radio-buttons-group-label'
                    name='row-radio-buttons-group'
                    defaultValue=''
                  >
                    {radioFields.map((i) => (
                      <FormControlLabel
                        key={i.name}
                        label={
                          <Typography component='h2' fontSize='16px'>
                            {t(i.label)}
                          </Typography>
                        }
                        value={i.name}
                        control={
                          <Radio
                            onBlur={onBlur}
                            onChange={onChange}
                            inputRef={ref}
                          />
                        }
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              )}
            />
            <Accordion sx={{marginTop: '10px'}}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{color: 'white'}} />}
                aria-controls='panel1a-content'
                id='panel1a-header'
                sx={accardionTitle}
              >
                <Typography sx={{fontSize: '17px', fontWeight: '400'}}>
                  {t('form.advancedSearch')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{lineHeight: 3, paddingTop: '20px'}}>
                <Selects
                  type='5'
                  text={t('form.severalDoc')}
                  registerText='refTypeId'
                />
                <Selects
                  type='7'
                  text={t('form.organSelect')}
                  registerText='authoritiesId'
                />
                <DatesGroup
                  text={t('form.dates.title')}
                  // exactDate='dateAdopted'
                  dateFrom='dateAdoptedFrom'
                  datedTo='dateAdoptedTo'
                />
                <Grid container spacing={2} sx={{mt: '2px'}}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    {/* <Fields text={t('form.number')} registerText='number' /> */}
                  </Grid>
                  <Grid sx={{width: '100px'}} item xs={12}>
                    <Selects
                      type='22'
                      text='Статус'
                      registerText='refStatusId'
                    />
                  </Grid>
                </Grid>
                <Fields
                  text={t('form.byName')}
                  registerText={lang === 'ru' ? 'nameRus' : 'nameKyr'}
                />
                <Fields
                  text={t('form.byText')}
                  registerText={
                    lang === 'ru' ? 'searchByTextRu' : 'searchByTextKg'
                  }
                />
                <Selects
                  type='20'
                  text={t('form.keywords')}
                  registerText='kluchSlov'
                />
                <Selects
                  type='21'
                  text={t('form.generalLegalClassifier')}
                  registerText='klassificator'
                />
                 <AdvancedResearch />
              </AccordionDetails>
            </Accordion>
            <Divider />
            <Button
              type={'submit'}
              variant={'contained'}
              sx={{mt: 1}}
              startIcon={<SearchIcon />}
            >
              {t('form.searchBtn')}
            </Button>
          </Box>
        </form>
      </FormProvider>
      <ImportantDocs />
    </Grid>
  )
}

const form = () => ({
  mt: 4,
  lineHeight: '1px',
  padding: '20px',
  background: 'white',
  borderRadius: '8px',
  maxWidth: '650px',
  boxShadow: '1',
})

const accardionTitle = () => ({
  backgroundColor: 'primary.main',
  color: 'white',
  borderRadius: '8px 8px 0 0',
})

const wrapper = (theme: Theme) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: 2,
  paddingBottom: '50px',
  [theme.breakpoints.between('xs', 'md')]: {
    display: 'block',
  },
})

export default RootForm
