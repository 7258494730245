import React from 'react'
import {useTranslation} from 'react-i18next'
import ClearIcon from '@mui/icons-material/Clear'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import PrintIcon from '@mui/icons-material/Print'
import {Box, Button, Modal, TextField, Theme} from '@mui/material'
import {useMutation} from '@tanstack/react-query'

import {$api, Loader} from 'src/utils'
import {getDocumentFile} from 'src/utils/endpoints'
import {CompareDoc} from 'src/modules/doc/sections/document-methods/compare'
import {IDoc, TEdition} from 'src/models/doc'

import {Download} from './download'
import {Editorial} from './editorial'
import OpenData from './open-data'
import {PrintDoc} from './print'

interface Props {
  doc: IDoc
  byText: string | null
  conditionToPrint?: () => void
  edition: TEdition
  printText: any
  nameDoc: string
  setByText: (e: string) => void
}

const ManageDoc = ({
  doc,
  byText,
  conditionToPrint,
  edition,
  setByText,
  printText,
  nameDoc,
}: Props) => {
  const {t} = useTranslation()
  const [open, setOpen] = React.useState(false)

  const {data, mutate, isLoading} = useMutation(async (fileName: string) => {
    const response = await $api.get(`${getDocumentFile}${fileName}`)
    return response.data
  })

  const handleOpen = (fileName: string) => {
    mutate(fileName)
    setOpen(true)
  }

  const searchByText = (event: IOnChangeEvent) => {
    setByText(event.target.value.replace(/[A-Za-z*+?^${}()|[\]\\=!-""<>]/g, ''))
  }
  const handleClose = () => setOpen(false)

  return (
    <Box sx={searchBtns}>
      <Editorial doc={doc} />
      <TextField
        size={'small'}
        placeholder={t('form.byText')}
        value={byText}
        onChange={searchByText}
        sx={{maxWidth: '200px'}}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 2,
        }}
      >
        {/* <PrintIcon
          fontSize={'large'}
          sx={print}
          onClick={() => conditionToPrint()}
        /> */}
        <PrintDoc isPdf fileName={doc.fileName} edition={edition?.id} nameDoc={nameDoc} />
        <PrintDoc edition={edition?.id} nameDoc={nameDoc} />
        <Download nameDoc={nameDoc} edition={edition} />
        <CompareDoc edition={edition} doc={doc} />
        <OpenData doc={doc} />
      </Box>
    </Box>
  )
}

const style = (data: string) => ({
  display: 'block',
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: data ? '80%' : '20%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 1,
})

const searchBtns = (theme: Theme) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '10px',
  [theme.breakpoints.between('xs', 1000)]: {
    marginTop: '15px',
  },
})
const print = (theme: Theme) => ({
  cursor: 'pointer',
  color: 'primary.main',
  [theme.breakpoints.between('xs', 'sm')]: {
    display: 'none',
  },
})

export default ManageDoc
